import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '../../static/css/sales-letter.css';
import { FunnelProvider } from '../context/FunnelContext';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function SalesLetterTemplate(props) {
  const {
    data,
    path,
    location: { href: funnelUrl }
  } = props;
  const funnelContent = data.allBuilderModels.oneSalesLetter.content;
  const productSelectorData = data.funnelPageData?.productSelector;
  const funnelDesign =
    data.allBuilderModels.oneSalesLetter.data?.design ?? `design2`;
  const defaultCategory =
    data.allBuilderModels.oneSalesLetter.data?.currentCategory ??
    `subscriptions`;
  const defaultProduct =
    defaultCategory === 'subscriptions' ? `subscription_0` : `onetime_0`;

  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [currentProduct, setCurrentProduct] = useState(defaultProduct);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tubIndex, setTubIndex] = useState(0);
  const [currentTubIndex, setCurrentTubIndex] = useState(0);
  const [frequency, setFrequency] = useState('1:month');
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [currentTub, setCurrentTub] = useState({});
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [submittedToCart, setSubmittedToCart] = useState(false);
  const [extraObjects, setExtraObjects] = useState({
    ...JSON.parse(productSelectorData?.extra)
  });

  const context = {
    ...productSelectorData,
    currentCategory: currentCategory,
    setCurrentCategory: setCurrentCategory,
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign: funnelDesign,
    tubIndex: tubIndex,
    setTubIndex: setTubIndex,
    currentTubIndex: currentTubIndex,
    setCurrentTubIndex: setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    submittedToCart: submittedToCart,
    setSubmittedToCart: setSubmittedToCart,
    extraObjects: extraObjects,
    setExtraObjects: setExtraObjects
  };

  return (
    <FunnelProvider value={context}>
      <Helmet
        bodyAttributes={{
          class: 'sales-letter-1'
        }}
      >
        <title>
          {(funnelContent && funnelContent.data.title) || defaultTitle}
        </title>
        <meta
          name="description"
          content={
            (funnelContent && funnelContent.data.description) ||
            defaultDescription
          }
        />
        <meta name="funnel-id" content={funnelContent.id} />
        <meta
          name="product-selector-id"
          content={funnelContent.data.products.id}
        />
        <meta
          name="magic-key"
          content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
        />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        name="sales-letter"
        content={funnelContent}
        data={{
          ...context
        }}
      />
    </FunnelProvider>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneSalesLetter(query: { id: $funnelId }, options: { cachebust: true }) {
        content
        data {
          design
          currentCategory
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default SalesLetterTemplate;
